<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input v-show="$i18n.locale==='zh'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')" maxlength="15"/>
      <el-input v-show="$i18n.locale==='en'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.decimal')">
      <el-input-number controls-position="right" :precision="0" :max="4" :min="0" size="small"
                       v-model="value.precision"/>
    </el-form-item>
    <el-form-item v-show="$i18n.locale==='zh'" :label="$t('formConfig.showChinese')">
      <el-switch v-model="value.showChinese"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "AmountInputConfig",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    getMaxlength() {
      if (this.$i18n.locale === 'zh') {
        return 15;
      }
      return 40;
    }
  }
}
</script>

<style scoped>

</style>
