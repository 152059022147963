<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input v-show="$i18n.locale==='zh'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')" maxlength="15"/>
      <el-input v-show="$i18n.locale==='en'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')" />
    </el-form-item>
    <el-form-item :label="$t('formConfig.upload.numLimitLabel')">
      <tip slot="label" :content="$t('formConfig.upload.numLimitTips')">{{
          $t('formConfig.upload.numLimitLabel')
        }}
      </tip>
      <el-input-number class="max-fill" controls-position="right" :precision="0" size="small" v-model="value.maxNumber"
                       :placeholder="$t('formConfig.upload.numLimitPlaceholder')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.upload.sizeLimitLabel')">
      <tip slot="label" :content="$t('formConfig.upload.sizeLimitTips')">{{
          $t('formConfig.upload.sizeLimitLabel')
        }}
      </tip>
      <el-input-number class="max-fill" controls-position="right" :precision="1" size="small" v-model="value.maxSize"
                       :placeholder="$t('formConfig.upload.sizeLimitPlaceholder')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.upload.typeLimitLabel')">
      <tip slot="label" :content="$t('formConfig.upload.typeLimitTips')">{{ $t('formConfig.upload.typeLimitLabel') }}
      </tip>
      <el-select size="small" style="width: 100%;" v-model="value.fileTypes" multiple
                 filterable allow-create default-first-option clearable
                 :placeholder="$t('formConfig.upload.typeLimitPlaceholder')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.upload.onlyReadLabel')">
      <el-switch v-model="value.onlyRead"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "FileUploadConfig",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
  padding: 0 12px 0 0;
}
</style>
