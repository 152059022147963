<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input v-show="$i18n.locale==='zh'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.date.dateTimeTips')" maxlength="15"/>
      <el-input v-show="$i18n.locale==='en'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.date.dateTimeTips')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.date.dateTimeFormatLabel')">
      <el-select size="small" v-model="value.format">
        <el-option :value="$t('common.yyyy')" :label="$t('formConfig.date.yyyy')"></el-option>
        <el-option :value="$t('common.yyyyMM')" :label="$t('formConfig.date.yyyyMM')"></el-option>
        <el-option :value="$t('common.yyyyMMdd')" :label="$t('formConfig.date.yyyyMMdd')"></el-option>
        <el-option :value="$t('common.yyyyMMddHHmm')" :label="$t('formConfig.date.yyyyMMddHHmm')"></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "DateTime",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    getMaxlength() {
      if (this.$i18n.locale === 'zh') {
        return 15;
      }
      return 40;
    }
  }
}
</script>

<style scoped>

</style>
