<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input v-show="$i18n.locale==='zh'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')" maxlength="15"/>
      <el-input v-show="$i18n.locale==='en'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')" />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "TextareaInput",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    getMaxlength() {
      if (this.$i18n.locale === 'zh') {
        return 15;
      }
      return 40;
    }
  }
}
</script>

<style scoped>

</style>
