<template>
  <div>
    <el-form label-position="top" label-width="90px">
      <el-form-item :label="$t('nodeConfig.Task.name')" prop="text" class="user-type">
        <el-input v-show="$i18n.locale==='zh'" type="text" v-model="selectedNode.name" maxlength="20"></el-input>
        <el-input v-show="$i18n.locale==='en'" type="text" v-model="selectedNode.name" ></el-input>
      </el-form-item>
      <el-form-item :label="$t('nodeConfig.Task.desc')" prop="text" class="user-type">
        <el-input type="textarea" v-model="nodeProps.taskDesc" :placeholder="$t('nodeConfig.Task.descPlaceholder')"
                  maxlength="200"></el-input>
      </el-form-item>
      <el-form-item :label="$t('nodeConfig.Task.userType')" prop="text" class="user-type">
        <el-radio-group v-model="nodeProps.assignedType">
          <el-radio v-for="t in approvalTypes" :label="t.type" :key="t.type">{{ t.name }}</el-radio>
        </el-radio-group>
        <div v-if="nodeProps.assignedType === 'ASSIGN_USER'">
          <el-button size="mini" icon="el-icon-plus" type="primary" @click="selectUser" round>
            {{ $t('nodeConfig.Task.assignUser') }}
          </el-button>
          <org-items v-model="nodeProps.assignedUser"/>
        </div>
        <div v-else-if="nodeProps.assignedType === 'SELF_SELECT'">
          <el-radio-group size="mini" v-model="nodeProps.selfSelect.multiple">
            <el-radio-button :label="false">{{ $t('nodeConfig.Task.selfSelect.single') }}</el-radio-button>
            <el-radio-button :label="true">{{ $t('nodeConfig.Task.selfSelect.multiple') }}</el-radio-button>
          </el-radio-group>
        </div>
        <!--        <div v-else-if="nodeProps.assignedType === 'LEADER_TOP'">-->
        <!--          <el-divider/>-->
        <!--          <el-form-item label="审批终点" prop="text" class="approve-end">-->
        <!--            <el-radio-group v-model="nodeProps.leaderTop.endCondition">-->
        <!--              <el-radio label="TOP">直到最上层主管</el-radio>-->
        <!--              <el-radio label="LEAVE">不超过发起人的</el-radio>-->
        <!--            </el-radio-group>-->
        <!--            <div class="approve-end-leave" v-if="nodeProps.leaderTop.endCondition === 'LEAVE'">-->
        <!--              <span>第 </span>-->
        <!--              <el-input-number :min="1" :max="20" :step="1" size="mini" v-model="nodeProps.leaderTop.level"/>-->
        <!--              <span> 级主管</span>-->
        <!--            </div>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <!--        <div v-else-if="nodeProps.assignedType === 'LEADER'">-->
        <!--          <el-divider/>-->
        <!--          <el-form-item label="指定主管" prop="text">-->
        <!--            <span>发起人的第 </span>-->
        <!--            <el-input-number :min="1" :max="20" :step="1" size="mini"-->
        <!--                             v-model="nodeProps.leader.level"-->
        <!--            ></el-input-number>-->
        <!--            <span> 级主管</span>-->
        <!--            <div style="color: #409EFF; font-size: small;">👉 直接主管为 第 1 级主管</div>-->
        <!--          </el-form-item>-->
        <!--        </div>-->
        <!--        <div v-else-if="nodeProps.assignedType === 'ROLE'">-->
        <!--          <el-button size="mini" icon="el-icon-plus" type="primary" @click="selectRole" round>选择系统角色</el-button>-->
        <!--          <org-items v-model="nodeProps.role"/>-->
        <!--        </div>-->
        <div v-else-if="nodeProps.assignedType === 'FORM_USER'">
          <el-form-item :label="$t('nodeConfig.Task.formUser')" prop="text" class="approve-end">
            <el-select style="width: 80%;" size="small" v-model="nodeProps.formUser"
                       :placeholder="$t('nodeConfig.Task.formUserPlaceholder')">
              <el-option v-for="op in forms" :label="op.title" :value="op.id" :key="op.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-else>
          <span class="item-desc">{{ $t("nodeConfig.Task.self") }}</span>
        </div>
      </el-form-item>
      <el-form-item prop="text">
        <el-checkbox v-model="nodeProps.taskDelay">{{ $t('nodeConfig.Task.taskDelayLabel') }}</el-checkbox>
        <el-tooltip class="item" effect="dark" :content="$t('nodeConfig.Task.taskDelay')" placement="top-start">
          <i class="el-icon-question" style="margin-left: 10px; font-size: medium; color: #b0b0b1"></i>
        </el-tooltip>
      </el-form-item>
      <!--      <el-form-item v-if="nodeProps.taskDelay">-->
      <!--        {{$t('nodeConfig.Task.delayTimeLabel')}}-->
      <!--        <el-time-picker-->
      <!--            v-model="nodeProps.delayTime"-->
      <!--            size="small"-->
      <!--            value-format="HH:mm"-->
      <!--            format="HH:mm"-->
      <!--            :picker-options="{-->
      <!--                    selectableRange: '00:00:00 - 23:59:59',-->
      <!--                  }"-->
      <!--            :placeholder="$t('nodeConfig.Task.taskDelayPlaceholder')">-->
      <!--        </el-time-picker>-->
      <!--      </el-form-item>-->
      <el-form-item prop="text">
        <el-checkbox v-model="nodeProps.broadcast">{{ $t('nodeConfig.Task.broadcast') }}</el-checkbox>
      </el-form-item>
      <el-form-item prop="text">
        <el-checkbox v-model="nodeProps.faceVerification">{{ $t('nodeConfig.Task.faceVerification') }}</el-checkbox>
      </el-form-item>
      <el-form-item prop="text">
        <el-checkbox v-model="nodeProps.listing">{{ $t('nodeConfig.Task.listing') }}</el-checkbox>
      </el-form-item>
      <el-form-item prop="text">
        <el-checkbox v-model="nodeProps.userStatistics">{{ $t('nodeConfig.Task.userStatistics') }}</el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('nodeConfig.Task.uploadTypeLabel')" prop="text" class="line-mode">
        <el-checkbox-group v-model="nodeProps.uploadType">
          <el-checkbox label="location">{{ $t('nodeConfig.Task.uploadType.location') }}</el-checkbox>
          <el-checkbox label="image">{{ $t('nodeConfig.Task.uploadType.image') }}</el-checkbox>
          <el-checkbox label="video">{{ $t('nodeConfig.Task.uploadType.video') }}</el-checkbox>
          <el-checkbox label="recording">{{ $t('nodeConfig.Task.uploadType.recording') }}</el-checkbox>
          <el-checkbox label="file">{{ $t('nodeConfig.Task.uploadType.file') }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!--      <el-form-item label="执行人为空时" prop="text" class="user-type">-->
      <!--        <el-radio-group v-model="nodeProps.nobody.handler">-->
      <!--          <el-radio label="TO_PASS">自动通过</el-radio>-->
      <!--&lt;!&ndash;          <el-radio label="TO_REFUSE">自动驳回</el-radio>&ndash;&gt;-->
      <!--          &lt;!&ndash;          <el-radio label="TO_ADMIN">转交审批管理员</el-radio>&ndash;&gt;-->
      <!--          <el-radio label="TO_USER">转交到指定人员</el-radio>-->
      <!--        </el-radio-group>-->

      <!--        <div style="margin-top: 10px" v-if="nodeProps.nobody.handler === 'TO_USER'">-->
      <!--          <el-button size="mini" icon="el-icon-plus" type="primary" @click="selectNoSetUser" round>选择人员</el-button>-->
      <!--          <org-items v-model="nodeProps.nobody.assignedUser"/>-->
      <!--        </div>-->

      <!--      </el-form-item>-->
      <div v-if="showMode">
        <el-divider/>
        <el-form-item :label="$t('nodeConfig.Task.mode.label')" prop="text" class="approve-mode">
          <el-radio-group v-model="nodeProps.mode">
            <!--            <el-radio label="NEXT">{{ $t('nodeConfig.Task.mode.next') }}</el-radio>-->
            <el-radio label="AND">{{ $t('nodeConfig.Task.mode.and') }}</el-radio>
            <!--            <el-radio-group label="AND">{{ $t('nodeConfig.Task.mode.and') }}</el-radio-group>-->
            <!--            <el-radio label="OR">或签（有一人同意即可）</el-radio>-->
          </el-radio-group>
        </el-form-item>
      </div>
      <el-divider>{{ $t('nodeConfig.Task.AdvancedSettings') }}</el-divider>
      <!--      <el-form-item :label="$t('nodeConfig.Task.assigneeLabel')" prop="text">-->
      <!--        <el-switch :inactive-text="$t('nodeConfig.Task.no')" :active-text="$t('nodeConfig.Task.yes')"-->
      <!--                   v-model="nodeProps.assignee"></el-switch>-->
      <!--      </el-form-item>-->
      <el-form-item :label="$t('nodeConfig.Task.signLabel')" prop="text">
        <el-switch :inactive-text="$t('nodeConfig.Task.no')" :active-text="$t('nodeConfig.Task.yes')"
                   v-model="nodeProps.sign"></el-switch>
        <el-tooltip class="item" effect="dark" :content="$t('nodeConfig.Task.signTips')" placement="top-start">
          <i class="el-icon-question" style="margin-left: 10px; font-size: medium; color: #b0b0b1"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item :label="$t('nodeConfig.Task.commentLabel')" prop="text">
        <el-switch :inactive-text="$t('nodeConfig.Task.no')" :active-text="$t('nodeConfig.Task.yes')"
                   v-model="nodeProps.comment"></el-switch>
      </el-form-item>
      <!--      <el-form-item label="执行期限（为 0 则不生效）" prop="timeLimit">-->
      <!--        <el-input style="width: 180px;" placeholder="时长" size="small" type="number"-->
      <!--                  v-model="nodeProps.timeLimit.timeout.value"-->
      <!--        >-->
      <!--          <el-select style="width: 75px;" v-model="nodeProps.timeLimit.timeout.unit" slot="append" placeholder="请选择">-->
      <!--            <el-option label="天" value="D"></el-option>-->
      <!--            <el-option label="小时" value="H"></el-option>-->
      <!--          </el-select>-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="执行期限超时后执行" prop="level" v-if="nodeProps.timeLimit.timeout.value > 0">-->
      <!--        <el-radio-group v-model="nodeProps.timeLimit.handler.type">-->
      <!--          <el-radio label="PASS">自动通过</el-radio>-->
      <!--&lt;!&ndash;          <el-radio label="REFUSE">自动驳回</el-radio>&ndash;&gt;-->
      <!--          &lt;!&ndash;          <el-radio label="NOTIFY">发送提醒</el-radi0 o>&ndash;&gt;-->
      <!--        </el-radio-group>-->
      <!--        <div v-if="nodeProps.timeLimit.handler.type === 'NOTIFY'">-->
      <!--          <div style="color:#409EEF; font-size: small">默认提醒当前执行人</div>-->
      <!--          <el-switch inactive-text="循环" active-text="一次" v-model="nodeProps.timeLimit.handler.notify.once"></el-switch>-->
      <!--          <span style="margin-left: 20px" v-if="!nodeProps.timeLimit.handler.notify.once">-->
      <!--							每隔-->
      <!--							<el-input-number :min="0" :max="10000" :step="1" size="mini"-->
      <!--                               v-model="nodeProps.timeLimit.handler.notify.hour"-->
      <!--              />-->
      <!--							小时提醒一次-->
      <!--						</span>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label=" 如果审批被驳回">-->
      <!--        <el-radio-group v-model="nodeProps.refuse.type">-->
      <!--          <el-radio label="TO_END">直接结束流程</el-radio>-->
      <!--          <el-radio label="TO_BEFORE">驳回到上级审批节点</el-radio>-->
      <!--          <el-radio label="TO_NODE">驳回到指定节点</el-radio>-->
      <!--        </el-radio-group>-->
      <!--        <div v-if="nodeProps.refuse.type === 'TO_NODE'">-->
      <!--          <span>指定节点:</span>-->
      <!--          <el-select style="margin-left: 10px; width: 150px;" placeholder="选择跳转步骤" size="small"-->
      <!--                     v-model="nodeProps.refuse.target"-->
      <!--          >-->
      <!--            <el-option v-for="(node, i) in nodeOptions" :key="i" :label="node.name" :value="node.id"></el-option>-->
      <!--          </el-select>-->
      <!--        </div>-->
      <!--      </el-form-item>-->
    </el-form>
    <org-picker :title="pickerTitle" multiple :type="orgPickerType" ref="orgPicker" :selected="orgPickerSelected"
                @ok="selected"
    />
  </div>
</template>
<script>
import OrgPicker from '@/components/common/OrgPicker'
import OrgItems from '../OrgItems'

export default {
  name: 'ApprovalNodeConfig',
  components: {OrgPicker, OrgItems},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    let approvalTypes = [];
    approvalTypes.push({name: this.$t('nodeConfig.Task.approvalTypes.assignUser'), type: 'ASSIGN_USER'})
    approvalTypes.push({name: this.$t('nodeConfig.Task.approvalTypes.selfSelect'), type: 'SELF_SELECT'})
    approvalTypes.push({name: this.$t('nodeConfig.Task.approvalTypes.self'), type: 'SELF'})
    approvalTypes.push({name: this.$t('nodeConfig.Task.approvalTypes.formUser'), type: 'FORM_USER'})
    return {
      showOrgSelect: false,
      orgPickerSelected: [],
      orgPickerType: 'user',
      approvalTypes: approvalTypes
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.selectedNode
    },
    nodeProps() {
      return this.$store.state.selectedNode.props
    },
    select() {
      return this.config.assignedUser || []
    },
    forms() {
      return this.$store.state.design.formItems.filter(f => {
        return f.name === 'UserPicker'
      })
    },
    pickerTitle() {
      switch (this.orgPickerType) {
        case 'user':
          return this.$t('nodeConfig.Task.selectOrg.user')
        case 'role':
          return this.$t('nodeConfig.Task.selectOrg.role')
        default:
          return null
      }
    },
    nodeOptions() {
      let values = []
      const excType = ['ROOT', 'EMPTY', 'CONDITION', 'CONDITIONS', 'CONCURRENT', 'CONCURRENTS']
      this.$store.state.nodeMap.forEach((v) => {
        if (excType.indexOf(v.type) === -1) {
          values.push({id: v.id, name: v.name})
        }
      })
      return values
    },
    showMode() {
      switch (this.nodeProps.assignedType) {
        case 'ASSIGN_USER':
          return this.nodeProps.assignedUser.length > 0
        case 'SELF_SELECT':
          return this.nodeProps.selfSelect.multiple
        case 'LEADER_TOP':
          return this.nodeProps.formUser !== ''
        case 'FORM_USER':
          return true
        case 'ROLE':
          return true
        default:
          return false
      }
    }
  },
  created() {

  },
  methods: {
    selectUser() {
      this.orgPickerSelected = this.select
      this.orgPickerType = 'user'
      this.$refs.orgPicker.show()
    },
    selectNoSetUser() {
      this.orgPickerSelected = this.config.nobody.assignedUser
      this.orgPickerType = 'user'
      this.$refs.orgPicker.show()
    },
    selectRole() {
      this.orgPickerSelected = this.select
      this.orgPickerType = 'role'
      this.$refs.orgPicker.show()
    },
    selected(select) {
      this.orgPickerSelected.length = 0
      select.forEach(val => this.orgPickerSelected.push(val))
    },
    removeOrgItem(index) {
      this.select.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-type {
  .el-radio {
    width: 110px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.line-mode {
  .el-radio {
    width: 150px;
    margin: 5px;
  }
}

.el-form-item__label {
  line-height: 25px;
}

.approve-mode {
  .el-radio {
    float: left;
    width: 100%;
    display: block;
    margin-top: 15px;
  }
}

.approve-end {
  position: relative;

  .el-radio-group {
    width: 160px;
  }

  .el-radio {
    margin-bottom: 5px;
    width: 100%;
  }

  .approve-end-leave {
    position: absolute;
    bottom: -5px;
    left: 150px;
  }
}

.el-divider--horizontal {
  margin: 10px 0;
}
</style>
