<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input v-show="$i18n.locale==='zh'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')" maxlength="15"/>
      <el-input v-show="$i18n.locale==='en'" size="small" v-model="value.placeholder"
                :placeholder="$t('formConfig.placeholderValue')" />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  components: {},
  props:{
    value:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
