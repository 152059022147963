import { translate } from '../../../i18n'

export const ValueType = {
  string: 'String',
  object: 'Object',
  array: 'Array',
  number: 'Number',
  date: 'Date',
  user: 'User',
  dept: 'Dept',
  dateRange: 'DateRange'
}
export const baseComponents = [ {
  name: translate('formItem.name.text'),
  components: [
    {
      title: translate('formItem.name.textInput'),
      name: 'TextInput',
      icon: 'el-icon-edit',
      value: '',
      valueType: ValueType.string,
      props: {
        required: false,
        enablePrint: true
      }
    },
    {
      title: translate('formItem.name.textareaInput'),
      name: 'TextareaInput',
      icon: 'el-icon-more-outline',
      value: '',
      valueType: ValueType.string,
      props: {
        required: false,
        enablePrint: true
      }
    }, {
      title: translate('formItem.name.numberInput'),
      name: 'NumberInput',
      icon: 'el-icon-edit-outline',
      value: '',
      valueType: ValueType.number,
      props: {
        required: false,
        enablePrint: true
      }
    },
    {
      title: translate('formItem.name.amountInput'),
      name: 'AmountInput',
      icon: 'iconfont icon-zhufangbutiezhanghu',
      value: '',
      valueType: ValueType.number,
      props: {
        required: false,
        enablePrint: true,
        showChinese: true,
        precision:4
      }
    }, {
      title: translate('formItem.name.description'),
      name: 'Description',
      icon: 'el-icon-warning-outline',
      value: '',
      valueType: ValueType.string,
      props: {
        required: false,
        enablePrint: true
      }
    }
  ]
}, {
  name: translate('formItem.name.select'),
  components: [
    {
      title: translate('formItem.name.selectInput'),
      name: 'SelectInput',
      icon: 'el-icon-circle-check',
      value: '',
      valueType: ValueType.string,
      props: {
        required: false,
        enablePrint: true,
        expanding: false,
        options: translate('formItem.name.options')
      }
    },
    {
      title: translate('formItem.name.multipleSelect'),
      name: 'MultipleSelect',
      icon: 'iconfont icon-duoxuankuang',
      value: [],
      valueType: ValueType.array,
      props: {
        required: false,
        enablePrint: true,
        expanding: false,
        options: translate('formItem.name.options')
      }
    }
  ]
},{
  name:translate('formItem.name.date'),
  components: [
    {
      title: translate('formItem.name.dateTime'),
      name: 'DateTime',
      icon: 'el-icon-date',
      value: '',
      valueType: ValueType.date,
      props: {
        required: false,
        enablePrint: true,
        format: translate('common.yyyyMMddHHmm')
      }
    },
    {
      title: translate('formItem.name.dateTimeRange'),
      name: 'DateTimeRange',
      icon: 'iconfont icon-kaoqin',
      valueType: ValueType.dateRange,
      props: {
        required: false,
        enablePrint: true,
        placeholder: [translate('formConfig.date.startTimePlaceholder'),
          translate('formConfig.date.endTimePlaceholder')],
        format: translate('common.yyyyMMddHHmm'),
        showLength: false
      }
    }
  ]
}, {
  name: translate('formItem.name.upload'),
  components: [
    {
      title: translate('formItem.name.imageUpload'),
      name: 'ImageUpload',
      icon: 'el-icon-picture-outline',
      value: [],
      valueType: ValueType.array,
      props: {
        required: false,
        enablePrint: true,
        maxSize: 5, //图片最大大小MB
        maxNumber: 10, //最大上传数量
        enableZip: true //图片压缩后再上传
      }
    },
    {
      title: translate('formItem.name.fileUpload'),
      name: 'FileUpload',
      icon: 'el-icon-folder-opened',
      value: [],
      valueType: ValueType.array,
      props: {
        required: false,
        enablePrint: true,
        onlyRead: false, //是否只读，false只能在线预览，true可以下载
        maxSize: 100, //文件最大大小MB
        maxNumber: 10, //最大上传数量
        fileTypes: [] //限制文件上传类型
      }
    }
  ]
}, {
  name: translate('formItem.name.other'),
  components: [
    {
      title: translate('formItem.name.userPicker'),
      name: 'UserPicker',
      icon: 'el-icon-user',
      value: [],
      valueType: ValueType.user,
      props: {
        required: false,
        enablePrint: true,
        multiple: false
      }
    }
  ]
}]
