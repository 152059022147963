<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input v-show="$i18n.locale==='zh'" size="small" v-model="value.placeholder[0]" :placeholder="$t('formConfig.startTimePlaceholder')" maxlength="15"/>
      <el-input v-show="$i18n.locale==='zh'" size="small" v-model="value.placeholder[1]" :placeholder="$t('formConfig.endTimePlaceholder')" maxlength="15"/>
      <el-input v-show="$i18n.locale==='en'" size="small" v-model="value.placeholder[0]" :placeholder="$t('formConfig.startTimePlaceholder')"/>
      <el-input v-show="$i18n.locale==='en'" size="small" v-model="value.placeholder[1]" :placeholder="$t('formConfig.endTimePlaceholder')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.date.dateTimeFormatLabel')">
      <el-select size="small" v-model="value.format">
        <el-option :value="$t('common.yyyy')" :label="$t('formConfig.date.yyyy')"></el-option>
        <el-option :value="$t('common.yyyyMM')" :label="$t('formConfig.date.yyyyMM')"></el-option>
        <el-option :value="$t('common.yyyyMMdd')" :label="$t('formConfig.date.yyyyMMdd')"></el-option>
        <el-option :value="$t('common.yyyyMMddHHmm')" :label="$t('formConfig.date.yyyyMMddHHmm')"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('formConfig.date.showLengthLabel')">
      <el-switch v-model="value.showLength"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "DateTimeRangeConfig",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
